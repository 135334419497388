/**
 * Displays the Webite Technique filter
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { useDispatch, useSelector } from 'react-redux';

import { useAPIPropTypes } from '../../../hooks';
import { FacetsPropTypes, FacetsDefaultProps } from '../Facets';

import { ReactComponent as SvgWebsiteTechnique } from './SvgWebsiteTechnique.svg';
import FilterListItem, { FilterListItemPropTypes } from '../FilterListItem';
import { SET_FILTERS, UPDATE_APPS_FILTERS } from '../../../state/actionTypes';

import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';

import { useTranslation } from 'react-i18next';
import { values } from 'lodash';

/**
 * Defines the prop types
 *
 * @see http://api.finsterdata.com/v1/companies/?country=nl&facets=apps&rows=0&token=d11e7916590604d1356a08579aad99dc6bd3cfd1
 *
 * @see http://api.finsterdata.com/v1/companies/?country=nl&facets=app_cats&rows=0&token=d11e7916590604d1356a08579aad99dc6bd3cfd1
 */
const propTypes = {
  apps: PropTypes.shape(FacetsPropTypes),
  appsSelected: PropTypes.arrayOf(PropTypes.string),
  appsCat: PropTypes.shape(FacetsPropTypes),
  appsCatSelected: PropTypes.arrayOf(PropTypes.string),
  apiCall: PropTypes.shape(useAPIPropTypes),
  filterListItem: PropTypes.shape(FilterListItemPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  apps: FacetsDefaultProps,
  appsSelected: [],
  appsCat: FacetsDefaultProps,
  appsCatSelected: [],
  apiCall: {
    path: {
      endpoint: 'companies',
    },
    params: {
      queryParams: {
        facets: 'apps,apps_cat',
        rows: '0',
      },
    },
    defaultData: [],
  },
  filterListItem: {
    icon: {
      svg: SvgWebsiteTechnique,
      size: 'small',
      border: 'none',
      viewBox: '0 0 511 511',
    },
  },
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    // Wrap long text
    height: 'auto',
    padding: 4,
    '& .MuiChip-label': {
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
      lineHeight: '1',
    },
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

/**
 * Displays the component
 */
const FilterWebsiteTechniqueCategory = (props) => {
  const { apiCall, filterListItem } = props;
  const { defaultData } = apiCall;

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  /**
   * Handles the filters
   */
  const { filters, facets } = useSelector((store) => store.defaultReducer);

  const { apps_cat } = filters;
  const [selected, setSelected] = useState([]);
 
  /**
   * Handles the API call
   */
  const [results, setResults] = useState(defaultData);

  useEffect(() => {
    if (facets && facets.apps_cat && facets.apps_cat.buckets) {
      setResults(facets.apps_cat.buckets);
    }
    if (!apps_cat) {
      setSelected([]);
    }
    return () => setResults([]);
  }, [facets, apps_cat]);

  /**
   * Handles the select change
   */
  const selectHandler = (data, value) => {
    if (!value) return;

    const appsCatBuckets = value.map((value) => value.val);

    dispatch({
      type: SET_FILTERS,
      payload: {
        apps_cat: appsCatBuckets,
        page: 1,
      },
    });

    setSelected(value);
  };

  /**
   * Handles deleting the website technique
   * @param {Object} value
   */
  const handleDelete = (value) => {
    dispatch({
      type: UPDATE_APPS_FILTERS,
      payload: {
        val: value.val,
        type: 'apps_cat',
      },
    });

    setSelected((prevState) => {
      return prevState.filter((item) => item.val !== value.val);
    });
  };

  const form = (
    <FormControl>
      <Autocomplete
        multiple
        filterSelectedOptions
        name="web-tech"
        options={results.sort((a, b) => -b.val.localeCompare(a.val))}
        getOptionLabel={(option) => option.val}
        onChange={selectHandler}
        value={selected}
        closeIcon={null}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label={t('website_technique_category')}
          />
        )}
        renderTags={() => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip
                size="small"
                key={shortid.generate()}
                label={value.val}
                className={classes.chip}
                onDelete={() => handleDelete(value)}
              />
            ))}
          </div>
        )}
      />
    </FormControl>
  );

  return (
    <>
      {results && results.length > 0 && (
        <FilterListItem
          {...filterListItem}
          primary={false}
          secondary={false}
          children={form}
        />
      )}
    </>
  );
};

FilterWebsiteTechniqueCategory.propTypes = propTypes;
FilterWebsiteTechniqueCategory.defaultProps = defaultProps;

export default FilterWebsiteTechniqueCategory;
export {
  propTypes as FilterWebsiteTechniqueCategoryPropTypes,
  defaultProps as FilterWebsiteTechniqueCategoryDefaultProps,
};
