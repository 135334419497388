import {
  RESET_DOWNLOADS_AND_COMPANIES,
  RESET_STATE_TO_DEFAULT,
  SET_COMPANIES,
  SET_COMPANIES_FOUND,
  SET_DOWNLOAD_BUTTONS,
  SET_DOWNLOAD_IS_PENDING,
  SET_FACETS,
  SET_FILTERED_PRICES,
  SET_FILTERS,
  SET_IS_LOADING,
  SET_STATE,
  SET_SUBSCRIPTION_DATA,
  SET_UPGRADE_INFO,
  SET_UPGRADE_PRICES,
  SET_VISITED_COMPANIES,
  UPDATE_APPS_FILTERS,
} from '../actionTypes';
import { initialState } from '../initialSearchState';
import { convertKeysToCamelCase } from '../../helpers/baseHelper';

export default function (state = initialState, action) {
  const { filters } = initialState;
  const { type, payload, exact } = action;

  switch (type) {
    case SET_STATE: {
      if (!payload || typeof payload !== 'object') {
        return state;
      }

      return {
        ...state,
        ...payload,
      };
    }
    case SET_IS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }
    case SET_SUBSCRIPTION_DATA: {
      const subscriptionsData = convertKeysToCamelCase(payload);

      return {
        ...state,
        subscriptionsData,
      };
    }
    case SET_UPGRADE_PRICES: {
      return {
        ...state,
        upgradePrices: payload,
      };
    }
    case SET_FILTERED_PRICES: {
      return {
        ...state,
        filteredPrices: payload,
      };
    }
    case SET_FILTERS: {
      let newFilters = exact
        ? { ...filters, ...payload }
        : { ...state.filters, ...payload };
      localStorage.setItem('FILTERS', JSON.stringify(newFilters));

      return {
        ...state,
        filters: newFilters,
      };
    }
    case UPDATE_APPS_FILTERS: {
      const { filters } = state;
      const { apps, apps_cat } = filters;

      const newFilters = {
        ...filters,
        province:
          payload.province !== undefined ? payload.province : filters.province,
        page: 1,
      };

      if (payload && payload.type === 'apps') {
        newFilters['apps'] = apps
          ? apps.filter((item) => item !== payload.val)
          : [];
      }

      if (payload && payload.type === 'apps_cat') {
        newFilters['apps_cat'] = apps_cat
          ? apps_cat.filter((item) => item !== payload.val)
          : [];
      }

      localStorage.setItem('FILTERS', JSON.stringify(newFilters));

      return {
        ...state,
        filters: newFilters,
      };
    }
    case SET_FACETS: {
      return {
        ...state,
        facets: {
          ...state.facets,
          ...payload,
        },
      };
    }
    case SET_VISITED_COMPANIES: {
      return {
        ...state,
        visitedCompanies: [...state.visitedCompanies, payload],
      };
    }
    case SET_DOWNLOAD_BUTTONS: {
      return {
        ...state,
        download: {
          buttons: payload,
        },
      };
    }
    case SET_DOWNLOAD_IS_PENDING: {
      return {
        ...state,
        downloadIsPending: payload,
      };
    }
    case SET_UPGRADE_INFO: {
      return {
        ...state,
        upgradeInfo: payload,
      };
    }
    case SET_COMPANIES_FOUND: {
      return {
        ...state,
        companiesFoundStatus: payload,
      };
    }
    case SET_COMPANIES: {
      const { companies, found } = payload;

      return {
        ...state,
        companies: {
          list: companies,
          pagination: { total: found },
          companiesFound: found,
        },
      };
    }
    case RESET_DOWNLOADS_AND_COMPANIES: {
      const { companies, download } = initialState;

      return {
        ...state,
        companies,
        download,
      };
    }
    case RESET_STATE_TO_DEFAULT: {
      return initialState;
    }
    default:
      return state;
  }
}
