export const SET_FILTERS = 'SET_FILTERS';
export const SET_FACETS = 'SET_FACETS';
export const SET_STATE = 'SET_STATE';
export const SET_DOWNLOAD_BUTTONS = 'SET_DOWNLOAD_BUTTONS';
export const SET_DOWNLOAD_IS_PENDING = 'SET_DOWNLOAD_IS_PENDING';
export const SET_UPGRADE_INFO = 'SET_UPGRADE_INFO';
export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_COMPANIES_FOUND = 'SET_COMPANIES_FOUND';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_VISITED_COMPANIES = 'SET_VISITED_COMPANIES';
export const SET_SUBSCRIPTION_DATA = 'SET_SUBSCRIPTION_DATA';
export const SET_UPGRADE_PRICES = 'SET_UPGRADE_PRICES';
export const SET_FILTERED_PRICES = 'SET_FILTERED_PRICES';

export const UPDATE_APPS_FILTERS = 'UPDATE_APPS_FILTERS';

export const RESET_DOWNLOADS_AND_COMPANIES = 'RESET_DOWNLOADS_AND_COMPANIES';
export const RESET_STATE_TO_DEFAULT = 'RESET_STATE_TO_DEFAULT';
