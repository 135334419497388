/**
 * Displays the Province filter
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';
import { useDispatch, useSelector } from 'react-redux';

import { useAPIPropTypes } from '../../../hooks';
import { SET_FILTERS, UPDATE_APPS_FILTERS } from '../../../state/actionTypes';

import { ReactComponent as SvgProvince } from './Province.svg';
import FilterListItem, { FilterListItemPropTypes } from '../FilterListItem';

import { displayFacets, FacetsPropTypes, FacetsDefaultProps } from '../Facets';

import { makeStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 *
 * @see http://api.finsterdata.com/api/companies/?country=gb&facets=province&rows=0
 */
const propTypes = {
  provinces: PropTypes.shape(FacetsPropTypes),
  selected: PropTypes.arrayOf(PropTypes.string),
  apiCall: PropTypes.shape(useAPIPropTypes),
  filterListItem: PropTypes.shape(FilterListItemPropTypes),
};

/**
 * Defines the default props
 */
const defaultProps = {
  provinces: FacetsDefaultProps,
  selected: [],
  apiCall: {
    path: {
      endpoint: 'companies',
    },
    params: {
      queryParams: {
        facets: 'province',
        rows: '0',
        // action: 'list',
      },
    },
    defaultData: [],
  },
  filterListItem: {
    icon: {
      svg: SvgProvince,
      size: 'small',
      border: 'none',
      viewBox: '0 0 511 511',
    },
  },
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
    // Wrap long text
    height: 'auto',
    padding: 4,
    '& .MuiChip-label': {
      overflowWrap: 'break-word',
      whiteSpace: 'normal',
      lineHeight: '1',
    },
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

/**
 * Displays the component
 */
const FilterProvince = (props) => {
  const { selected: defaultSelected, apiCall, filterListItem } = props;
  const { defaultData } = apiCall;

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  /**
   * Handles the filters
   */
  const { filters, facets } = useSelector((store) => store.defaultReducer);

  /**
   * Initializes the selected state
   */
  const [selected, setSelected] = useState(
    filters?.province || defaultSelected
  );

  /**
   * Handles the API call
   */
  const [results, setResults] = useState(defaultData);

  useEffect(() => {
    if (facets && facets.province && facets.province.buckets) {
      setResults(facets.province.buckets);
    } else {
      setResults(defaultData);
    }
  }, [facets]);

 
  /**
   * Handles the select change
   */
  const selectHandler = (data, value) => {
    if (!value || value.length < 1) return;

    const selectedValues = selected.map(item => item.val);
    const newValue = value.find(item => !selectedValues.includes(item.val));
 
    if(!newValue) return ;

    const provinces = value.map(province => province.val)

    dispatch({
      type: SET_FILTERS,
      payload: {
        province: provinces,
        page: 1,
      },
    });

    setSelected(value);
  };

  /**
   * Handles deleting the selected province
   * @param {Object} value
   */
  const handleDelete = (value) => {
    const newProvince = selected.filter(item => item.val !== value.val);

    dispatch({ type: UPDATE_APPS_FILTERS, payload: {
      province: newProvince.map(province => province.val)
    } });

    setSelected(newProvince);
  };

  const form = (
    <FormControl>
      <Autocomplete
        multiple
        filterSelectedOptions
        name="province"
        options={results.sort((a, b) => -b.val.localeCompare(a.val))}
        getOptionLabel={(option) => option.val}
        onChange={selectHandler}
        value={selected}
        closeIcon={null}
        renderInput={(params) => (
          <TextField {...params} variant="standard" label={t('province')} />
        )}
        renderTags={() => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip
                size="small"
                key={shortid.generate()}
                label={value.val}
                className={classes.chip}
                onDelete={() => handleDelete(value)}
              />
            ))}
          </div>
        )}
      />
    </FormControl>
  );

  return (
    <>
      {results && results.length !== 0 && (
        <FilterListItem
          {...filterListItem}
          primary={false}
          secondary={false}
          children={form}
        />
      )}
    </>
  );
};

FilterProvince.propTypes = propTypes;
FilterProvince.defaultProps = defaultProps;

export default FilterProvince;
export {
  propTypes as FilterProvincePropTypes,
  defaultProps as FilterProvinceDefaultProps,
};
