import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid, Typography, Link } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Input from '../../Input';

import * as yup from 'yup';
import { WORDPRESS_APP_URL } from '../../../constants/envVars';

/**
 * Defines the prop types
 */
const propTypes = {
  defaultValues: PropTypes.shape({
    email: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  error: PropTypes.string,
  resetFormError: PropTypes.func,
};

/**
 * Defines the default props
 */
const defaultProps = {
  defaultValues: {
    email: '',
  },
  onSubmit: () => {},
  error: null,
  resetFormError: () => {},
};

/**
 * Displays the component
 */
const ResetPasswordForm = (props) => {
  const { defaultValues, onSubmit, error, resetFormError } = props;
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup.string().required(t('email_required')).email(t('email_invalid')),
  });

  const { register, errors, reset, handleSubmit, formState } = useForm({
    defaultValues,
    validationSchema: schema,
  });

  useEffect(() => {
    if (formState.dirty && error) {
      resetFormError(null);
    }

    if (!formState.isValid) {
      formState.isSubmitted = false;
    } else if (formState.isSubmitted && formState.isValid) {
    }
  }, [formState, resetFormError, error]);

  const handleFormSubmit = (...args) => {
    if (!formState.isValid) {
      reset(args[0]);
    }
    onSubmit(...args);
    reset(args[0]);
  };

  return (
    <Box width={1}>
      <form onSubmit={handleSubmit(handleFormSubmit)} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              fullWidth
              type="email"
              inputRef={register}
              inputEmail={{
                name: 'email',
                label: t('email'),
                error: errors.email?.message,
                required: true,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button fullWidth variant="contained" color="primary" type="submit">
              {t('send')}
            </Button>
          </Grid>
        </Grid>
      </form>
      <Grid container spacing={2} justify="space-between">
        <Grid item xs>
          <Typography variant="body2">
            <Link href={WORDPRESS_APP_URL}>{t('toHome')}</Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

ResetPasswordForm.propTypes = propTypes;
ResetPasswordForm.defaultProps = defaultProps;

export default ResetPasswordForm;
export {
  propTypes as ResetPasswordFormPropTypes,
  defaultProps as ResetPasswordFormDefaultProps,
};
