import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import i18n from '../../../i18n';
import { useTranslation } from 'react-i18next';
import shortid from 'shortid';
import { useSelector } from 'react-redux';

/**
 * Defines the prop types
 */

const Record = {
  value: PropTypes.string,
  label: PropTypes.string,
  entries: PropTypes.number,
};
const propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape(Record)),
  selected: PropTypes.shape({
    value: PropTypes.number,
    name: PropTypes.string,
  }),
  all: PropTypes.string,
  top: PropTypes.string,
  otherAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

/**
 * Defines the default props
 *
 */

const defaultProps = {
  records: [
    {
      label: i18n.t('top_100', { number: 100 }),
      value: 'top_100',
      entries: 100,
    },
    {
      label: i18n.t('top_1000', { number: 1000 }),
      value: 'top_1000',
      entries: 1000,
    },
    {
      label: i18n.t('top_5000', { number: 5000 }),
      value: 'top_5000',
      entries: 5000,
    },
    { label: i18n.t('all'), value: 'all', entries: 0 },
    { label: i18n.t('top'), value: 'top', entries: 0 },
    {
      label: i18n.t('other'),
      value: 'other',
      entries: 0,
    },
  ],
  otherAmount: '0',
  selected: { value: 100, name: 'top_100' },
};

/**
 * Updates the `all` and `top` records with the `found`, `credits` values
 */
const displayRecords = (props) => {
  const { records, found, credits } = props;

  return records.map((record) => {
    const { label, value } = record;

    return value === 'all'
      ? {
          ...record,
          entries: found,
          label: `${label} ${found}`,
        }
      : value === 'top'
      ? {
          ...record,
          entries: credits > found ? found : credits,
          label: `${label} ${credits > found ? found : credits}`,
        }
      : record;
  });
};

/**
 * Converts a record (`top 100`) to a row query param (`&row=100`)
 */
const convertRecordToRow = (props) => {
  const { record, otherAmount } = props;
  if (record.name === 'other') {
    return Number(otherAmount);
  }

  /**
   * Strips down chars and returns only numbers
   */

  return record.value;
};

/**
 * Displays the component
 */
const ResultsDownloadRecords = (props) => {
  const { record, setRecord, otherAmount, setOtherAmount } = props;
  const { t } = useTranslation();

  const { subscriptionsData, companies } = useSelector(
    (store) => store.defaultReducer
  );
  const { credits } = subscriptionsData;
  const { companiesFound: found } = companies;

  /**
   * Manages the checkboxes
   */
  const records = displayRecords({
    ...props,
    credits: parseInt(credits),
    found: parseInt(found),
  });

  const handleCheckboxChange = (event) => {
    const { target } = event;
    const { value, name } = target;

    setRecord({ value, name });
  };

  useEffect(() => {
    return () => {
      setRecord({ value: 100, name: 'top_100' });
    };
  }, []);

  const items = records.map((item) => {
    const { value, label, entries } = item;
    return (
      <Grid item xs={12} sm="auto" key={shortid.generate()}>
        {(entries > 0 || value === 'other') && (
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={value === record.name}
                name={value}
                value={entries}
                onChange={handleCheckboxChange}
              />
            }
            label={label}
          />
        )}
      </Grid>
    );
  });

  /**
   * Manages the text input
   */

  const handleInputChange = (event) => {
    const {
      target: { value },
    } = event;
    setOtherAmount(value);
  };

  return (
    <FormControl component="fieldset">
      <FormGroup>
        <Grid container justify="space-between">
          {items}
        </Grid>
      </FormGroup>
      {record.name === 'other' && (
        <TextField
          type="number"
          disabled={record.name !== 'other'}
          name="otherAmount"
          label={t('other_amount')}
          value={otherAmount}
          onChange={handleInputChange}
        />
      )}
    </FormControl>
  );
};

ResultsDownloadRecords.propTypes = propTypes;
ResultsDownloadRecords.defaultProps = defaultProps;

export default ResultsDownloadRecords;
export {
  propTypes as ResultsDownloadRecordsPropTypes,
  defaultProps as ResultsDownloadRecordsDefaultProps,
  Record as RecordPropTypes,
  convertRecordToRow,
};
