import { fromJS, Map, mergeDeep } from 'immutable';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import useData, { getUseDataHookProps } from '../useData';
import { API_ENDPOINT } from '../../constants/envVars';

/**
 * Defines the prop types
 */
const propTypes = {
  autocomplete: PropTypes.string,
  path: PropTypes.shape({
    url: PropTypes.string,
    version: PropTypes.string,
    endpoint: PropTypes.string,
  }),
  params: PropTypes.shape({
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
    // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
    init: PropTypes.object,
    queryParams: PropTypes.object,
  }),
  initialData: PropTypes.any,
  watch: PropTypes.any,
  config: PropTypes.any,
};

/**
 * Defines the default props
 */
const defaultProps = {
  autocomplete: '',
  path: {
    url: 'https://api.finsterdata.com',
    version: 'v1',
    endpoint: 'login',
  },
  params: {
    init: {},
    queryParams: {},
  },
  defaultData: 'Loading ...',
  watch: null,
  config: {},
};

/**
 * Checks if the response is an error
 */
const isApiError = (data) => {
  return data?.status === 'error';
};

/**
 * Returns the error message from the response
 */
const getApiErrorMessage = (data) => {
  return data?.user_message || '';
};

/**
 * Returns the token from the response
 */
const getApiToken = (data) => {
  return data?.token;
};

/**
 * Checks if the API call was made.
 *
 * - Sometimes the underlying data library doesn't makes the API call.
 * - Maybe because of caching, or other errors
 */
const wasApiCallMade = (data) => {
  return data !== undefined && data !== null;
};

/**
 * Returns the API call status and a message
 */
const getAPICallStatus = (data) => {
  if (!wasApiCallMade(data)) {
    return {
      successful: false,
      message: null,
    };
  }

  if (isApiError(data)) {
    return {
      successful: false,
      message: getApiErrorMessage(data),
    };
  }

  return {
    successful: true,
    message: 'API request was successful',
  };
};

/**
 * General functions
 * - They should work with all REST APIs
 * - API specific parts of the code are marked with comments
 */

/**
 * Encodes params (object) to query string
 */
const encodeParams = (props) => {
  const { params, prefix } = props;

  return Map.isMap(fromJS(params))
    ? `${prefix}${queryString.stringify(params, {
        skipNull: true,
        arrayFormat: 'bracket',
      })}`
    : '';
};

/**
 * Deep merges various API props to form the final params
 *
 * - requestProps - the request specific props, usually defined in the caller component's PropTypes
 * - requestLiveProps - the request specific props which, defined inside the component
 */
const mergeApiParams = (props) => {
  const { requestProps, requestLiveProps } = props;

  return mergeDeep(
    fromJS(defaultProps),
    fromJS(requestProps),
    fromJS(requestLiveProps)
  ).toJS();
};

/**
 * Creates a path (url) to a resource
 */
const createPathToResource = (props) => {
  const { path, params, autocomplete } = props;
  const { url, version, endpoint } = path;
  const { init, queryParams, token } = params;

  const paramsWithToken = token
    ? { ...queryParams, token: token }
    : queryParams;

  const encodedQueryParams = encodeParams({
    params: paramsWithToken,
    prefix: '?',
  });

  /**
   * // TODO: With the second argument (init) fetch is not working
   *
   * - As a workaround we add init params to the query string
   */
  const encodedInitParams =
    init && init.body ? encodeParams({ params: init.body, prefix: '&' }) : '';

  if (!autocomplete) {
    return `${url}/${version}/${endpoint}${encodedQueryParams}${encodedInitParams}`;
  } else if (autocomplete === 'location') {
    return `${url}/${version}/${endpoint}?term=${queryParams.term}&country=${queryParams.country}`;
  }
};

/**
 * A general fetcher function
 *
 * - Both `SWR` and `react-async` are built on `fetch`
 */
const fetcher = async ({ props }) => {
  const { params } = props;
  const { fetcherOptions = {} } = params;
  const pathToResource = createPathToResource(props);
  const response = await fetch(pathToResource, fetcherOptions);

  /**
   * With this API (Finster) we just simply return the response
   * The response always includes the errors, if there are any
   * No need to complicate with throwing errors here
   * When error is used the components can enter in infinite rendering because their state gets updated continuously
   */
  return response.json();
};

/**
 * Displays the component
 */
const useAPI = (props) => {
  const { path, params, initialData, watch, config } = props;

  /**
   * This is useData strategy specific ...
   * // TODO: Make it strategy independent
   */
  const { data, reload } = useData(
    getUseDataHookProps({
      options: {
        promiseFn: fetcher,
        promiseFnParams: {
          props: { path: path, params: { ...params } },
        },
        initialValue: initialData,
        watch: watch,
        config: config,
      },
    })
  );

  return { data, reload };
};

useAPI.propTypes = propTypes;
useAPI.defaultProps = defaultProps;

export default useAPI;
export {
  isApiError,
  fetcher,
  getApiErrorMessage,
  mergeApiParams,
  mergeApiParams as mergeAPIParams,
  createPathToResource,
  getApiToken,
  getAPICallStatus,
  propTypes as useAPIPropTypes,
  defaultProps as useAPIDefaultProps,
};
