/**
 * Displays the current active filters
 */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty, capitalize, cloneDeep } from 'lodash';
import moment from 'moment';

import { FilterAgeDefaultProps } from '../FilterAge';
import { SET_FILTERS } from '../../../state/actionTypes';
import { getFilterBy } from '../../../helpers/filtersHelper';

import { Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';

import { useTranslation } from 'react-i18next';

/**
 * Defines the prop types
 */
const propTypes = {
  filters: PropTypes.arrayOf(PropTypes.string),
};

/**
 * Defines the default props
 */
const defaultProps = {
  filters: ['Filter 1', 'Filter 2', 'Filter 3', 'Filter 4'],
};

/**
 * Styles the component
 */
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    minHeight: 32,
  },
  chip: {
    marginRight: theme.spacing(0.5),
  },
}));

const hasLocationValue = (filters) => {
  return filters.some((filter) => {
    const [key, value] = filter;
    return key === 'location' && value !== null;
  });
};

const hasFilterValue = (value) => {
  switch (typeof value) {
    case 'object':
      return !isEmpty(value);
    case 'number':
      return value > 0;
    case 'string':
      return value.length > 0;
    default:
      return true;
  }
};

/**
 * Checks if a filter is set
 */
const isFilterSet = (filter, filters) => {
  const [key, value] = filter;

  /**
   * Show the KM filter only if a location is provided
   */
  if (key === 'km' && hasLocationValue(filters) && hasFilterValue(value)) {
    return true;
  } else if (key === 'km') {
    return false;
  }

  return hasFilterValue(value);
};

/**
 * Creates a label for a FilteredBy item
 */
const getFilterLabel = (filter, translations) => {
  const [key, value] = filter;


  const {
    industry,
    text,
    km,
    data_exists,
    employees,
    apps,
    apps_cat,
    changedsince,
    industryCode,
  } = translations;

  const { values } = FilterAgeDefaultProps;

  switch (key) {
    case 'industry':
      return `${industry}: ${capitalize(
        value?.map((item) => item.val).join(', ')
      )}`;
    case 'text':
      return `${text}: ${value}`;
    case 'km':
      return `${km}: ${value}`;
    case 'data_exists':
      return `${data_exists}: ${capitalize(value)}`;
    case 'from':
    case 'to':
      return `${employees} ${key}: ${value}`;
    case 'province':
      return `${capitalize(key)}: ${value.join(', ')}`;
    case 'apps':
      return `${apps}: ${capitalize(value.join(', '))}`;
    case 'apps_cat':
      return `${apps_cat}: ${capitalize(value.join(', '))}`;
    case 'changedSince':
      return `${changedsince}: ${moment(value).format('LL')}`;
    case 'age': {
      return `${capitalize(key)}: ${values
        .filter((age) => age.value === Number(value))
        .map((age) => age.name)}`;
    }
    case 'industryCode': {
      return `${industryCode}: ${value.join(', ')}`;
    }
    default:
      return `${capitalize(key)}: ${capitalize(value)}`;
  }
};

/**
 * Displays the component
 */
const FilteredBy = () => {
  const { filters } = useSelector((store) => store.defaultReducer);
  const filtersMap = Object.entries(filters);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const classes = useStyles();
  const { chips, chip } = classes;

  /**
   * Match filter names with filter display values
   *
   * - A filter name in the API (`category`) can differ from the filter display name (`Industry`)
   *
   * // TODO: These translations are duplicated in the respective filters ...
   */
  const translations = {
    industry: t('industry'),
    industryCode: t('industryCode'),
    text: t('text'),
    km: t('km'),
    data_exists: t('data_exists'),
    employees: t('employees'),
    apps: t('apps'),
    apps_cat: t('apps_cat'),
    changedsince: t('changedsince'),
  };

  /**
   * Handles the delete actions
   *
   * - The country filter cannot be removed
   */
  const handleDelete = (key) => () => {
    if (key === 'country') {
      return;
    }

    dispatch({ type: SET_FILTERS, payload: getFilterBy(key) });
  };

  const handleDeleteAll = () => {
    const { country } = filters;

    dispatch({ type: SET_FILTERS, payload: { country }, exact: true });
  };

  /**
   * Prepares the items
   */
  const items =
    (filtersMap &&
      filtersMap
        .filter((filter) => isFilterSet(filter, filtersMap))
        .filter(
          (filter) => !['country', 'page', 'rows', 'action'].includes(filter[0])
        )
        .map((filter) => {
          const [key] = filter;
          const label = getFilterLabel(filter, translations);

          return (
            <Chip
              className={chip}
              key={key}
              label={label}
              onDelete={handleDelete(key)}
            />
          );
        })) ||
    [];

  return (
    <FormControl>
      <Box className={chips}>
        {items.length > 0 && (
          <Chip
            className={chip}
            key="clear-all"
            label={t('clear_all')}
            onDelete={handleDeleteAll}
          />
        )}
        {items}
      </Box>
    </FormControl>
  );
};

FilteredBy.propTypes = propTypes;
FilteredBy.defaultProps = defaultProps;

export default FilteredBy;
export {
  propTypes as FilteredByPropTypes,
  defaultProps as FilteredByDefaultProps,
};
